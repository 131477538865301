<template>
  <div>
    <div class="border border-solid border-blue-200 pt-4">
      <datatable
        :data="investments.data"
        :columns="investments.columns"
        :filters="investments.filters"
        :query="investments.query"
        :ajax="true"
        :ajax-pagination="true"
        :url="`${this.$baseurl}/invest/admin/investments`"
        :on-click="click"
        :selectable="true"
        :all-selectable="false"
        ref="table"
      >
        <template #header>
          <search-form
            v-model="investments.query"
            class="mb-5"
            placeholder="Search for user's name, investment's name,id"
            @submit="reloadTable"
          />

          <div class="flex items-center mb-5">
            <template v-for="(filter, filterIndex) in investments.filters">
              <div
                :key="filterIndex"
                class="processor-filter-item"
                :class="{ active: currentFilter === filter }"
                @click.prevent="setFilter(filter)"
              >
                {{ filter.title }}
              </div>
            </template>
          <div class="ml-auto">
            <button class="btn-sm btn-red" @click.prevent="$refs.activateInvestmentsPNDModal.open">Mass Activate PND</button>
            <button class="btn-sm btn-green ml-4" @click.prevent="$refs.deactivateInvestmentsPNDModal.open">Mass Deactivate PND</button>
          </div>
          </div>
        </template>
      </datatable>
    </div>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-10"
      ref="activateInvestmentsPNDModal"
    >
      <h4 class="text-xl font-bold mb-6">Activate PND On Selected Investments</h4>

      <template v-if="pndStatusUpdateError">
        <div class="alert alert-red-soft mb-10">
          <div class="alert-icon">!</div>
          <span>{{ pndStatusUpdateError }}</span>
        </div>
      </template>

      <div class="mb-6">Are you sure you want to continue?</div>

      <div class="flex flex-row items-center">
        <button
          type="button"
          class="btn btn-red btn-md w-full"
          @click.prevent="activatePndOnAllSelectedInvestments"
        >
          <span v-if="pndStatusUpdateLoading">Activating...</span>
          <span v-else>Go Ahead!</span>
        </button>
      </div>
    </modal>
    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-10" 
      ref="deactivateInvestmentsPNDModal"
    >
      <h4 class="text-xl font-bold mb-6">Deactivate PND On Selected Investments</h4>

      <template v-if="pndStatusUpdateError">
        <div class="alert alert-red-soft mb-10">
          <div class="alert-icon">!</div>
          <span>{{ pndStatusUpdateError }}</span>
        </div>
      </template>

      <div class="mb-6">Are you sure you want to continue?</div>

      <div class="flex flex-row items-center">
        <button
          type="button"
          class="btn btn-green btn-md w-full"
          @click.prevent="deactivatePndOnAllSelectedInvestments"
        >
          <span v-if="pndStatusUpdateLoading">Deactivating...</span>
          <span v-else>Go Ahead!</span>
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentFilter: null,
      pndStatusUpdateLoading: false,
      pndStatusUpdateError: '',
      investments: this.$options.resource([], {
        query: '',
        filters: [
          { name: 'all', title: 'All' },
          { name: 'active', title: 'Active' },
          { name: 'liquidated', title: 'Liquidated' },
          { name: 'withdrawn', title: 'Withdrawn' },
          { name: 'reinvested', title: 'Reinvested' },
          { name: 'closed', title: 'Closed' },
        ],
        selected: null,
        columns: [
          {
            name: 'user_id',
            th: 'User Id',
          },
          {
            name: 'user_name',
            th: 'Name',
            // render: investment =>
            //   `${investment?.user?.name} ${investment?.user?.last_name || 'N/A'}`
          },
          {
            name: 'id',
            th: 'Invest Id',
          },
          {
            name: 'name',
            th: 'Investment Name',
          },
          {
            name: 'amount',
            th: 'Amount',
            render: (investment) =>
              `₦ ${this.$options.filters.currency(investment.amount)}`,
          },
          {
            name: 'interest',
            th: 'Interest',
            render: (investment) =>
              `₦ ${this.$options.filters.currency(investment.earnings)}`,
          },
          {
            name: 'days',
            th: 'Days',
          },
          {
            name: 'pnd',
            th: 'PND',
            render: (investment, pnd) => this.pndStatusCheck(pnd),
          },
          {
            name: 'percentage',
            th: 'Percentage',
          },
          {
            name: 'tax',
            th: 'Tax',
          },
          {
            name: 'status',
            th: 'Status',
            render: (investment, status) => this.investmentStatusCheck(status),
          },
          {
            name: 'active_at',
            th: 'Active At',
            render: (investment, date) =>
              this.$moment(date).format('MMM Do YYYY'),
          },
          {
            name: 'closing_at',
            th: 'Closing Date',
            render: (investment, date) =>
              this.$moment(date).format('MMM Do YYYY'),
          },
        ],
      }),
    };
  },
  methods: {
    click(row) {
      window.open(
        this.$router.resolve({
          name: 'staff-view',
          params: {
            staffId: row?.user_id,
          },
        }).href,
        '_blank'
      );
    },
    userDeleted() {
      this.reloadTable();
    },
    userModalClosed() {
      this.users.selected = null;
    },
    reloadTable() {
      this.$refs.table.loadAjaxData();
    },
    setFilter(filter) {
      if (this.currentFilter === filter) {
        this.currentFilter = null;
      } else {
        this.currentFilter = filter;
      }

      this.$refs.table.clickedFilter(this.currentFilter);
    },
    async activatePnd(selectedInvestment) {
      this.pndStatusUpdateLoading = true;
      await this.sendRequest('admin.invest.activatePndOnInvestment', selectedInvestment?.id);
      this.pndStatusUpdateLoading = false;
    },
    async deactivatePnd(selectedInvestment) {
      console.log(selectedInvestment);
      this.pndStatusUpdateLoading = true;
      await this.sendRequest('admin.invest.deactivatePndOnInvestment', selectedInvestment?.id);
      this.pndStatusUpdateLoading = false;
    },
    async activatePndOnAllSelectedInvestments() {
      const selectedInvestments = this.$refs.table.selected;
      if (selectedInvestments.length > 0) {
        const selectedInvestmentPromises = selectedInvestments.map(selectedInvestment => (async () => {this.activatePnd(selectedInvestment?.row)})());
          Promise.all(selectedInvestmentPromises).then(() =>
          this.$success({
            title: 'Operation Successful',
            body: `PND successfully activated on Investments`,
            button: 'Okay',
          })).catch(() => 
          this.$error({
              title: 'Operation Failed',
              body: `Some or all of the investment PND activation might have failed`,
              button: 'Okay',
          })).finally(() => {
            this.pndStatusUpdateLoading = false;
            this.reloadTable();
            this.$refs.activateInvestmentsPNDModal.close();
        });
      } else {
        this.pndStatusUpdateError = 'You need to have at least one investment selected'
      }
    },
    deactivatePndOnAllSelectedInvestments() {
      const selectedInvestments = this.$refs.table.selected;
      if (selectedInvestments.length > 0) {
          const selectedInvestmentPromises = selectedInvestments.map(selectedInvestment => (async () => {this.deactivatePnd(selectedInvestment?.row)})());
          console.log(selectedInvestmentPromises);
          Promise.all(selectedInvestmentPromises).then(() =>
          this.$success({
            title: 'Operation Successful',
            body: `PND successfully deactivated on Investments`,
            button: 'Okay',
          })).catch(() => {
            this.$error({
                title: 'Operation Failed',
                body: `Some or all of the investment PND deactivation might have failed`,
                button: 'Okay',
              });
          }).finally(() => {
            this.pndStatusUpdateLoading = false;
            this.reloadTable();
            this.$refs.deactivateInvestmentsPNDModal.close()
          });
      } else {
        this.pndStatusUpdateError = 'You need to have at least one investment selected'
      }

    },
    pndStatusCheck(pnd) {
      switch (pnd) {
        case 1:
          return `<div class="rounded-sm border border-red-500 text-red-500 bg-red-100 text-xs px-2 py-1 inline-block">
            active
          </div>`;
        case 0:
        default:
          return `<div class="rounded-sm border border-blue-500 text-blue-500 bg-blue-100 text-xs px-2 py-1 inline-block">
              inactive
            </div>`;
      }
    },
    investmentStatusCheck(status) {
      switch (status) {
        case 'closed':
          return `<div class="rounded-sm border border-blue-500 text-blue-500 bg-blue-100 text-xs px-2 py-1 inline-block">
              ${status}
            </div>`;
        case 'liquidated':
          return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-2 py-1 inline-block">
              ${status}
            </div>`;
        case 'running':
        default:
          return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-2 py-1 inline-block">
              ${status}
            </div>`;
      }
    },
  },
};
</script>
